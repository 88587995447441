.logo-container {
    z-index: -999;
    width: 400px;
    height: 609px;
    opacity: .3;
    position: absolute;
    top: 0;
    right: 15%;
    bottom: 0;
    left: auto;
    margin: auto;
  
    .solid-logo {
      position: absolute;
      top: auto;
      right: auto;
      bottom: auto;
      left: 0;
      margin: auto;
      width: 100%;
      transform: rotateZ(30deg) !important;
    }
  }